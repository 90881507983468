import * as React from "react"
import Layout from "../../components/layout"
import MaskIpad from "../../images/maskid/maskid_mockup_ipad.jpg"
import MaskMockup from "../../images/maskid/maskid_mockup.jpg"
import { ArrowNarrowRightIcon } from "@heroicons/react/outline"

const MaskId = () => {
  return (
    <Layout>
      <div className="my-24 w-full md:w-4/5 mx-auto">
        <div className="flex items-baseline h-96 flex-col justify-center">
          <h1 className="text-5xl md:text-6xl font-black"><span className="highlight px-0.5">MaskID</span></h1>
          <p className="text-lg mt-4">Face Mask Identification</p>
          <div className="flex h-8 mt-4">
            <p className="rounded-full px-4 py-2 bg-ethan-blue text-white text-xs font-bold mr-2">
              web
            </p>
            <p className="rounded-full px-4 py-2 bg-ethan-blue text-white text-xs font-bold mr-2">
              AI
            </p>
            <p className="rounded-full px-4 py-2 bg-ethan-blue text-white text-xs font-bold mr-2">
              academic
            </p>
          </div>
        </div>
        <div className="my-10 flex flex-col gap-10">
          <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 bg-gray-100 rounded-lg">
            <h4 className="text-xl font-bold">ABOUT</h4>
            <div>
              <p className="text-lg">
                A website to encourage people to wear masks, and wear them
                properly. This project was for my Data in Emerging Media and
                Technology class.
              </p>
              <div className="grid grid-cols-2 gap-4 mt-6">
                <p className="font-semibold">Spring 2019</p>
                <p>TailwindCSS, Teachable Machine, HTML</p>
                <p className="font-semibold">Website</p>
                <p>
                  <strong>Client:</strong> Academic Work
                </p>
              </div>
            </div>
          </div>
          <a
            href="https://masked.ethanh.works"
            target="_blank" rel="noreferrer"
            className="p-10 bg-gray-100 rounded-lg text-xl font-bold text-center flex justify-center items-center group"
          >
            Visit the Site <span className="w-6 ml-2"><ArrowNarrowRightIcon/></span>
          </a>
          <img
            className="col-span-1 md:col-span-2 rounded-lg"
            src={MaskMockup}
            alt="mockup"
          />
          <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 bg-gray-100 rounded-lg">
            <h4 className="text-xl font-bold">OBJECTIVE</h4>
            <div>
              <p className="text-lg">
                The objective of this project was to display incoming data in a
                meaningful way. Given that this class was in the middle of the
                COVID-19 pandemic, I decided to create something relevent.
              </p>
            </div>
          </div>
          <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 bg-gray-100 rounded-lg">
            <h4 className="text-xl font-bold">SOLUTION</h4>
            <div>
              <p className="text-lg">
                I decided to create an artificial intelligence that can detect
                whether or not the user is wearing a mask. If the user is
                wearing a mask it can also detect if they are wearing it
                properly. In addition to the artificial intelligence, the site
                also takes data from the New York Times to display how likely
                people in the user's area are to wear a mask in public. Finally,
                the site also displays a live count of the number of confirmed
                COVID-19 cases globally.
              </p>
            </div>
          </div>
          <img className="col-span-1 md:col-span-2 rounded-lg" src={MaskIpad} alt="mockup"/>
        </div>
      </div>
    </Layout>
  )
}

export default MaskId
